import { Link } from "gatsby-plugin-react-intl";
import request from "graphql-request";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import "../assets/css/blog.scss";
import Head from "../components/head";
import Layout from "../components/layout";
import Loading from "../components/loading";
import ShowProvider from "../components/showProvider";
import SingleBlogCard from "../components/singleBlogCard";
import searchIcon from "../images/search.svg";

function Blog({ pageContext }) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [categories, setCategories] = useState([]);
  const [currentCategory, setCurrentCategory] = useState("[]");
  const [phrase, setPhrase] = useState({});
  const [search, setSearch] = useState("");

  useEffect(() => {
    const fetch = async () => {
      const res = await request(
        process.env.GATSBY_GRAPHQL_URL,
        `{
          articles(locale: "${pageContext.intl.language}",limit: 4, start: 0,where:{title_contains:"${search}",articles_category:{category_in:${currentCategory}}}) {
            
            title
            description
            content
            thumbnail {
              url
            }
            updated_at
            slug
            articles_category{
              category
            }
            
          }
          articlesCategories {
            category
          }
          phrase(locale:"${pageContext.intl.language}"){
            search
            nothingFound
          }
        }
        `
      );
      return res;
    };
    fetch().then((items) => {
      setData(items.articles);
      setCategories(items.articlesCategories);
      setLoading(false);
      setPhrase(items.phrase);
    });
  }, [currentCategory, search]);

  function Pagination() {
    const start = pageContext.currentPage > 3 ? pageContext.currentPage - 4 : 0;
    const stop =
      pageContext.currentPage + 3 > pageContext.pages
        ? pageContext.pages
        : pageContext.currentPage + 3;

    const ans = [];
    if (pageContext.currentPage - 3 > 1) {
      ans.push(
        <Link className="blog__main__posts__pagination__single">...</Link>
      );
    }
    for (let index = start; index < stop; index++) {
      ans.push(
        <Link
          key={index}
          to={`/blog/${index == 0 ? "" : index * 1 + 1}`}
          className={`blog__main__posts__pagination__single ${
            index + 1 == pageContext.currentPage &&
            "blog__main__posts__pagination__single--active"
          }`}
        >
          {index + 1}
        </Link>
      );
    }
    if (pageContext.currentPage + 3 < pageContext.pages) {
      ans.push(
        <Link className="blog__main__posts__pagination__single">...</Link>
      );
    }
    return ans;
  }
  return (
    <ShowProvider>
      <Layout initial={false} contactUs={false} />
      <div className="blog">
        <div className="blog__hero">
          <Layout
            initial={false}
            contactUs={false}
            className="blog__hero-wrapper"
          >
            <h1 className="blog__hero-text">Blog</h1>
          </Layout>
        </div>
      </div>
      <Layout nav={false} initial={false} contactUs={false}>
        <div />
        <Row>
          <Col className="blog__main__navigation">
            <div className="blog__main__navigation__categories">
              {loading ? (
                <Loading litle={true} />
              ) : (
                categories.map(({ category }, key) => (
                  <p
                    key={key}
                    className={`blog__main__navigation__categories__category ${
                      `["${category}"]` === currentCategory &&
                      "blog__main__navigation__categories__category--active"
                    }`}
                    onClick={() => {
                      setCurrentCategory(`["${category}"]`);
                      setSearch("");
                    }}
                  >
                    {category}
                  </p>
                ))
              )}
            </div>
            <div className="blog__main__navigation__search">
              <div className="blog__main__navigation__search-container">
                <input
                  type="text"
                  name="search"
                  className="blog__main__navigation__search-input"
                  placeholder={phrase.search}
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
                <button
                  type="submit"
                  className="blog__main__navigation__search-submit"
                >
                  <img src={searchIcon} alt="search" />
                </button>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="blog__main__posts">
          {loading ? (
            <Loading />
          ) : data.length >= 1 ? (
            data.map((item, key) => {
              return <SingleBlogCard data={item} key={key} />;
            })
          ) : (
            <div className="d-flex justify-content-center my-5">
              {phrase.nothingFound}
            </div>
          )}
        </Row>
        <Row>
          <Col className="blog__main__posts__pagination">
            <Pagination />
          </Col>
        </Row>
      </Layout>
      <Layout nav={false} initial={false} />
    </ShowProvider>
  );
}

export default Blog;
