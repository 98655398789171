import dayjs from "dayjs";
import { Link } from "gatsby-plugin-react-intl";
import parse from "html-react-parser";
import React from "react";
import { Col, Row } from "react-bootstrap";
import clock from "../images/clock.svg";
import ReadTime from "../lib/readTime";

function SingleBlogCard({ data }) {
  const strapiHost = process.env.GATSBY_API_URL;

  return (
    <Col className="blog__main__posts__post" xs={11} lg={4}>
      <Link to={`/posts/${data.slug}`} className="text-decoration-none">
        <Row>
          <Col className="blog__main__posts__post__image-wrapper" xs={12}>
            <img
              src={`${strapiHost}${data.thumbnail.url}`}
              className="blog__main__posts__post__image"
            />
            <div className="blog__main__posts__post__read-time">
              <img src={clock} />
              {ReadTime(data.content)} min
            </div>
          </Col>
          <Col className="blog__main__posts__post--header-wrapper" xs={12}>
            <h3 className="blog__main__posts__post--header">{data.title}</h3>
          </Col>
          <Col className="blog__main__posts__post--desc" xs={12}>
            {parse(data.description)}
          </Col>
          <Col className="blog__main__posts__post--footer" xs={12}>
            <p>
              <b>
                {data.articles_category.category
                  ? data.articles_category.category
                  : ""}
              </b>{" "}
              | {dayjs(data.updated_at).format("DD.MM.YYYY r.")}
            </p>
          </Col>
        </Row>
      </Link>
    </Col>
  );
}
export default SingleBlogCard;
