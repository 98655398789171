const ReadTime = (text) => {
  const words = text ? text.trim().split(/\s+/).length : 100;
  const perMinute = 100; // ludzie czytają ok. 220 na minute
  const av = words / perMinute;
  if (av < 1) {
    return "< 1";
  }
  return Math.floor(av);
};

export default ReadTime;
